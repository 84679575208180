<template lang="html">
  <li class="documento-el">
    <article class="polaroid">
      <p class="fecha" style="height: 20px"></p>
      <p class="nombre">{{fileContent.title}}</p>
      <div class="tipo">
        <a
          :href="fileContent.file.url"
          target="_blank"
        >
          <div
            :style="`background-image:url(` + fileContent.header_img.url + `)`"
            class="preview"
          ></div>
        </a>
      </div>
    </article>
    <p class="descripcion column-start">
      {{fileContent.description}}
    </p>

    <a
      class="descargar"
      :href="fileContent.file.url"
      target="_blank"
      >Descargar</a
    >
    <a
      class="enlace-cover"
      :href="fileContent.file.url"
      target="_blank"
    ></a>
  </li>
 
</template>

<script lang="js">

  export default  {
    name: 'file-el',
    props: ['fileContent'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}
</script>

<style scoped>
.file-el {
}
</style>
